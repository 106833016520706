
const ClinicHourHeader = ({ days, hours }) => {
    return (
      <div className="clinic-hour-header">
        <h5>{days}</h5>
        <p>{hours}</p>
      </div>
    );
  };

export default ClinicHourHeader;