import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonCard from "../Buttons/ButtonCard";
import {
  faCalendar,
  faMedkit,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import ClinicHourHeader from "../ClinicHourHeader";

const AppointmentsCard = () => {
  const navigate = useNavigate();
  return (
    <div className="services-card">
      <h2 className="font-weight-bold ">Unsere Sprechzeiten</h2>
      <div>
        <ClinicHourHeader
          days={"Montag, Dienstag, Donnerstag und Freitag"}
          hours={"7:30 - 12:00 Uhr & 15:00 - 18:00 Uhr"}
        />
        <ClinicHourHeader days={"Mittwoch"} hours={"7:30 - 12 Uhr"} />
      </div>
      Weitere Termine nach Vereinbarung
      <div className="buttons-container">
        <ButtonCard
          text={"Termin verabreden"}
          icon={faCalendar}
          onClick={() => {
            navigate("/appointments");
          }}
        />
        <ButtonCard text={"05151 24230 "} icon={faPhone} />
        <ButtonCard
          text={"Rezept online"}
          icon={faMedkit}
          onClick={() => {
            navigate("/prescription");
          }}
        />
      </div>
    </div>
  );
};

export default AppointmentsCard;
