

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Routes from './Routes/Routes';
import './App.css';
function App() {

  return (

    <Router>
      <Routes/>
    </Router>

  );

}


export default App;
