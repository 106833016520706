import ClinicNavbar from "../ClinicNavbar";
import Footer from "../footer";

import ClinicForm from "../Forms/ClinicForm";
import ClinicSection from "../ClinicSection";
import { Col, Row, Container } from "react-bootstrap";

const Appointment = () => {
  return (
    <>
      <ClinicNavbar />
      <Container fluid className="article">
        <Row>
          <Col xs={12} sm={6} md={6} className="left-home-body p-5 ">
            <ClinicSection
              title={"Online Terminbuchungs"}
              desc={
                "Sie können bei uns ganz bequem Termine online buchen! Bitte beachten Sie dabei folgende Punkte."
              }
              paragraph={`Auch unsere Ressourcen sind begrenzt, wir können daher leider nicht alle Patienten bei uns aufnehmen, sondern konzentrieren uns auf Patienten aus unserem Einzugsgebiet Hameln. Bei Erstkontakt vereinbaren Sie daher bitte telefonisch 05151 24230 einen Termin. So helfen Sie uns bei der Koordination und Planung der eingehenden Anmeldungen.

Spezialtermine können nicht online gebucht werden (z.B. Vorsorgeuntersuchungen, Diagnostik, Labor etc.)!

Bei Infektbeschwerden dürfen keine Online-Termine gebucht werden, hierfür bieten wir eine gesonderte Infektsprechstunde an!`}
            />
          </Col>
          <Col xs={12} sm={6} md={6} className="right-home-body">
            <ClinicForm />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Appointment;
