import { faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const FooterHeader = ({ text }) => {
  return (
    <div className="clinic-section footer-line">
      <h5>{text}</h5>
    </div>
  );
};

export const FooterColumn = ({ title, children }) => {
  return (
    <Col className="px-5">
      <FooterHeader text={title} />
      <Row className="py-3 text-success">{children}</Row>
    </Col>
  );
};

const Footer = () => {
  const numbers = [
    {
      title: "Praxis-Telefon",
      text: "05151 24230",
    },
  ];

  const emergencyNumbers = [
    {
      title: "Ärztlicher Notdienst",
      text: "116 117",
    },
    {
      title: "Apothekennotdienst",
      text: "0800 2282280",
    },
    {
      title: "Apothekennotdienst online abfragen",
      text: "",
    },
    {
      title: "Giftinformationszentrum Nord",
      text: "0551 19240",
    },
    {
      title: "Frauenhaus",
      text: "05151 25299",
    },
  ];
  const officeHours = [
    {
      title: "Montag, Dienstag, Donnerstag und Freitag",
      text: "7:30 - 12:00 Uhr & 15:00 - 18:00 Uhr",
    },
    {
      title: "Mittwoch",
      text: "7:30 - 12 Uhr ",
    },
    {
      title: "",
      text: "Weitere Termine nach Vereinbarung",
    },
  ];
  const columns = [
    {
      title: "Kontakt",
      children: [
        <div>
          <h5>Praxis am Ring</h5>
          <p>Drs. Kuwert / Ziegenhorn und Aboumaila</p>
          <p>Ärzte für Allgemeinmedizin, Innere und Palliativmedizin</p>
        </div>,
        <p>164er Ring 9 </p>,
        <p>31785 Hameln</p>,
        <h6>
          <FontAwesomeIcon icon={faPhone} /> 05151 24230
        </h6>,
        <h6>
          <FontAwesomeIcon icon={faMailBulk} />
          <a
            className="text-decoration-none text-success"
            href="mailto:info@praxisamring-hameln.de"
          >
            {" "}
            info@praxisamring-hameln.de
          </a>
        </h6>,
      ],
    },
    {
      title: "Parxisnummern",
      children: numbers.map((item, index) => (
        <div key={index}>
          <h6>{item.title}</h6>
          <p>{item.text}</p>
        </div>
      )),
    },
    {
      title: "Notfallnummer",
      children: emergencyNumbers.map((item, index) => (
        <div key={index}>
          <h6>{item.title}</h6>
          <p>{item.text}</p>
        </div>
      )),
    },
    {
      title: "Sprechstuende",
      children: officeHours.map((item, index) => (
        <div key={index}>
          <h6>{item.title}</h6>
          <p>{item.text}</p>
        </div>
      )),
    },
  ];

  const links1 = [
    {
      title: "Praxis",
      link: "/",
    },

    {
      title: "Team",
      link: "/about",
    },
    {
      title: "Kontakt",
      link: "/contact",
    },
    {
      title: "Datenschutz",
      link: "/data-protection",
    },
    {
      title: "Impressum",
      link: "/impressum",
    },
  ];

  const links4 = [
    {
      title: "Termin Online",
      link: "/appointments",
    },
    {
      title: "Online Rezeptbestellung",
      link: "/prescription",
    },
    {
      title: "Überweisung Online",
      link: "/referral",
    },
  ];

  return (
    <>
      <Container fluid className="footer-style">
        <Row>
          {columns.map((column, index) => (
            <FooterColumn key={index} title={column.title}>
              {column.children}
            </FooterColumn>
          ))}
        </Row>
        <Row>
          <FooterColumn
            title="Links"
            children={
              <Row>
                <Col>
                  {links1.map((item, index) => (
                    <p>
                      <a
                        className="text-decoration-none text-success"
                        href={item.link}
                        key={index}
                      >
                        {item.title}
                      </a>
                    </p>
                  ))}
                </Col>

                <Col>
                  {links4.map((item, index) => (
                    <p>
                      <a
                        className="text-decoration-none text-success"
                        href={item.link}
                        key={index}
                      >
                        {item.title}
                      </a>
                    </p>
                  ))}
                </Col>
              </Row>
            }
          />
        </Row>
      </Container>
      <div className="footer-copyright text-center py-3 bg-success text-light">
        © {new Date().getFullYear()} Copyright:
        <a className="text-decoration-none text-light" href="">
          {" "}
          praxisamring-hameln.de
        </a>
      </div>
    </>
  );
};

export default Footer;
