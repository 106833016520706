import ClinicNavbar from "../ClinicNavbar";
import Footer from "../footer";

import HomeBody from "./HomeBody";
import HomeAbout from "./HomeAbout";
import HomeServices from "./HomeServices";
import { Row, Container } from "react-bootstrap";
import HomeNews from "./HomeNews";


const Home = () => {

  return (
    <>
      <ClinicNavbar />
      <Container fluid>
        <Row>
          <HomeBody />
          <HomeAbout />
          <HomeNews/>
    
          <HomeServices />
          <Footer />
        </Row>
      </Container>

    
    </>
  );
};

export default Home;
