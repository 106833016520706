const ButtonHome = ({ text, onClick }) => {
    return (
      <div className="button-home">
        <button onClick={onClick} >
          {text}
        </button>
      </div>
    );
  };

  export default ButtonHome;