import ClinicNavbar from "../ClinicNavbar";
import Footer from "../footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import ClinicSection from "../ClinicSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMedkit,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import DoctorCard from "../Cards/DoctorCard";
const About = () => {
  const columnData = [
    {
      icon: faCalendar,
      desc: "Online Terminbuchung",
      paragraph: "Sie können bei uns ganz bequem Termine online buchen!",
    },
    {
      icon: faUserDoctor,
      desc: "Qualifizierte Ärzte",
      paragraph: "Unser Team besteht aus hochqualifizierten Fachärzten.",
    },
    {
      icon: faMedkit,
      desc: "Online Rezept",
      paragraph: "Bestellen Sie ganz einfach online Ihr Rezept.",
    },
  ];
  return (
    <>
      <ClinicNavbar />
      <div className="article">
        <Col xs={12} sm={6} md={6} className="left-home-body p-5 ">
          <ClinicSection
            title={"Über uns"}
            desc={"Lernen Sie unser Praxis-Team kennen"}
            paragraph={`Unser Praxisteam arbeitet eng zusammen. Wir erfüllen die Erwartungen unserer Patienten und die eigenen Ansprüche. Lernen sie uns kennen.`}
          />
        </Col>
        <Col className="right-home-body"></Col>
      </div>
      <Container fluid>
        <Row>
          <div className="d-flex flex-column justify-content-center align-items-center p-2">
            <ClinicSection title={"Praxis am Ring Team"} />
            <ClinicSection desc={"Heilung Im Herzen, Fürsoge Im Fokus"} />
          </div>
        </Row>

        <Row>
          {columnData.map((item, index) => (
            <Col xs={12} sm={8} md={4} key={index}>
              <div className="about-card">
                <FontAwesomeIcon
                  icon={item.icon}
                  color="#41b06e"
                  size="2xl"
                  className="m-3"
                />
                <h3>{item.desc}</h3>
                <p>{item.paragraph}</p>
              </div>
            </Col>
          ))}
        </Row>

        <Row className="article">
          <Row className="p-5 bg-light bg-opacity-75">
            <ClinicSection
              title={"Team"}
              desc={"Unsere Ärzte"}
              paragraph={`Unser Praxisteam besteht aus erfahrenen Ärzten, die sich um Ihre Gesundheit kümmern. Lernen Sie unsere Ärzte kennen und erfahren Sie mehr über ihre Fachgebiete und Qualifikationen.`}
            />
            <Col>
              <DoctorCard
                name="Dr.med. Gerd Kuwert"
                specialty="Facharzt für Allgemein und Palliativmedizin"
              />
            </Col>
            <Col>
              <DoctorCard
                name="Dr.med. Holger Ziegenhorn"
                specialty="Facharzt Innere Medizin"
              />
            </Col>
            <Col>
              <DoctorCard
                name="Hassan Aboumaila"
                specialty="Facharzt für Innere und Palliativmedizin"
              />
            </Col>
          </Row>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default About;
