// DoctorCard.jsx
import React from "react";
import { Card } from "react-bootstrap";

const DoctorCard = ({ name, specialty }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="fw-bold fs-5">{name}</Card.Header>
      <Card.Body>
        <Card.Text>{specialty}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default DoctorCard;
