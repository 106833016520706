import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import ClinicSection from '../ClinicSection';
import ClinicSlider from '../ClinicSlider';

const HomeNews = () => {
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch('/admin/news');
                const data = await response.json();
                setNewsData(data);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews();
    }, []);

    return (
        <Container fluid>
            <div className='p-5'>
                <ClinicSection title={"Informationen und Neugikeiten"} />
                {newsData.length === 0 ? (
                    <div className="empty-cards">
                        <div className="empty-card">Keine Neuigkeiten verfügbar</div>
                    </div>
                ) : (
                    <ClinicSlider slidesToShow={1} slidesToScroll={1} services={newsData} />
                )}
            </div>
        </Container>
    );
};

export default HomeNews;