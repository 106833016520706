import { Col, Container, Row } from "react-bootstrap";
import ClinicNavbar from "../ClinicNavbar";
import Footer from "../footer";
import PrescriptionForm from "../Forms/PrescriptionForm";
import ClinicSection from "../ClinicSection";
const Prescription = () => {
  return (
    <>
      <ClinicNavbar />
      <Container fluid className="article">
        <Row>
          <Col xs={12} sm={6} md={6} className="left-home-body p-5 ">
            <ClinicSection
              title={"Online Rezeptbestellung"}
              desc={"Bestellen Sie ganz einfach online Ihr Rezept"}
              paragraph={`Sie können hier nur Medikamente bestellen, die wir Ihnen bereits als Dauertherapie verordnet haben (Wiederholungsrezepte). Wichtig sind die genaue Bezeichnung, die Wirkstärke und die Packungsgrösse.
Zum Quartalsanfang benötigen wir Ihre Versicherungskarte. Auch Überweisungen für Kontrolluntersuchungen können Sie hier bestellen.`}
            />
          </Col>
          <Col xs={12} sm={6} md={6} className="right-home-body">
            <PrescriptionForm />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};
export default Prescription;
