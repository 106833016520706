import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AppointmentsCard from "../Cards/AppointmentsCard";
import logoSlogan from "../../assets/logo.png";
import  ClinicSection  from "../ClinicSection";

const HomeBody = () => {
  return (
    <Container fluid className="home-body">
      <Row>
        <Col xs={12} sm={12} md={6} className="left-home-body">
          <div>
            <div className="home-body-slogan">
              <img src={logoSlogan} alt="slogan" />
            </div>

            <div className="px-5">
            <h1>Praxis am Ring</h1>
            <ClinicSection desc={"Ihre hausärztliche Gemeinschaftspraxis"} paragraph={`In unserer modernen Hausarztpraxis stehen unsere Patientinnen und
              Patienten im Mittelpunkt. Wir respektieren Ihre individuellen
              Wünsche, nutzen moderne Diagnose- und Therapiemethoden und suchen
              gemeinsam nach den besten Lösungen. Wir wollen Ihr erster
              Ansprechpartner in Gesundheitsfragen für Sie und Ihre Familie
              sein.`} />

        
            </div>
            
          </div>
        </Col>
        <Col xs={12}  md={6} sm={12}className="right-home-body ">
          <AppointmentsCard />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeBody;
