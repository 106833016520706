import React from "react";
import Appointment from "../components/Appointment/Appointment";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Referral from "../components/Referral/Referral";
import Prescription from "../components/Presciption/Prescription";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
// import ProtectedRoute from "./protectedRoutes";
import Error401 from "../components/Errors/Errors";
import DataProtection from "../components/DataProtection";
import Impressum from "../components/Impressum";

function Paths() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/appointments" element={<Appointment />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/referral" element={<Referral />} />
      <Route exact path="/prescription" element={<Prescription />} />
      <Route exact path="/data-protection" element={<DataProtection />} />
      <Route exact path="/impressum" element={<Impressum/>} />
    {/*  Error routes  */}
        <Route exact path="/error-401" element={<Error401 />} />

    </Routes>
  );
}

export default Paths;
