import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/logo.png";

function ClinicNavbar() {
 
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand
            href="/"
            className="d-flex justify-content-center align-items-center text-success"
          >
            <img src={logo} className="nav-logo me-2" alt="Logo" />
            <h3 className="mt-2">Praxis am Ring</h3>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav " />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-success text-uppercase fs-5 fw-medium">
              <Nav.Link href="/"> Praxis</Nav.Link>
              <Nav.Link href="/about">Über uns</Nav.Link>
              <Nav.Link href="/contact">Kontakt</Nav.Link>
              <NavDropdown title="Leistungen" id="basic-nav-dropdown">
                <NavDropdown.Item href="/appointments">
                  Termin Verabreden
                </NavDropdown.Item>
                <NavDropdown.Item href="/prescription">
                  Online Rezept
                </NavDropdown.Item>
                <NavDropdown.Item href="/referral">
                  Überweisung
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ClinicNavbar;
