const ClinicSection = ({ title = null, desc = null, paragraph = null }) => {
  return (
    <div className="clinic-section">
      <h5>{title}</h5>
      <h3>{desc}</h3>
      <p style={{ whiteSpace: "pre-line" }}>{paragraph}</p>
    </div>
  );
};

export default ClinicSection;
