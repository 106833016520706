import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 const ButtonCard = ({ text, icon, onClick }) => {
  return (
      <button className="button-card" onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
        {text}
      </button>
 
  );
};

export default ButtonCard;