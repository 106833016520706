import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import ClinicSection from "../ClinicSection";
import ClinicSlider from "../ClinicSlider";
export default function HomeServices() {
  const [fetchedServiceData, setFetchedServiceData] = useState([]);
  const serviceData = [
    {
      title: "Online-Terminbuchung",
      desc: "Patienten können Termine bequem online buchen.",
    },
    {
      title: "Online-Rezept-Nachfüllung",
      desc: "Bestellen Sie Rezept-Nachfüllungen einfach online.",
    },
    {
      title: "Überweisung an einen Spezialarzt",
      desc: "Fragen Sie nach einer Überweisung zu einem Spezialarzt.",
    },
  ];

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await fetch('/admin/services');
        const data = await response.json();
        setFetchedServiceData(data);
      } catch (error) {
        console.error('Error fetching service data:', error);
      }
    };

    fetchServiceData();
  }, []);
  return (
    <Container fluid>
      <Row className="p-5">
        <div>
          <ClinicSection
            title={"Leistungen"}
            desc={"Unsere Leistungen und Zusatz­quali­fika­tionen"}
          />
          <ClinicSlider
            slidesToShow={3}
            slidesToScroll={1}
            type={'services'}
            services={fetchedServiceData}
          />
        </div>
      </Row>
    </Container>
  );
}
