import ClinicNavbar from "../ClinicNavbar";
import Footer, { FooterHeader } from "../footer";
import building from "../../assets/images/contact/building.jpg";
import { Link } from "react-router-dom";
import { faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Card } from "react-bootstrap";
import ClinicSection from "../ClinicSection";

const Contact = () => {
  const officeHours = [
    {
      title: "Montag, Dienstag, Donnerstag und Freitag",
      text: "7:30 - 12:00 Uhr & 15:00 - 18:00 Uhr",
    },
    {
      title: "Mittwoch",
      text: "7:30 - 12 Uhr ",
    },
    {
      title: "",
      text: "Weitere Termine nach Vereinbarung",
    },
  ];
  const columns = [
    {
      title: "Kontakt",
      children: [
        <div>
          <h5>Praxis am Ring</h5>
          <p>
            Drs. Kuwert / Ziegenhorn und Aboumaila
          </p>
          <p>Ärzte für Allgemeinmedizin,
          Innere und Palliativmedizin.</p>
        </div>,
        <p>164er Ring 9 </p>,
        <p>31785 Hameln</p>,
        <h6>
          <FontAwesomeIcon icon={faPhone} /> 05151 24230
        </h6>,
        <h6>
           <FontAwesomeIcon icon={faMailBulk} /> 
           <a className="text-decoration-none text-success" href="mailto:info@praxisamring-hameln.de"> info@praxisamring-hameln.de</a>
        </h6>,
      ],
    },

    {
      title: "Sprechstuende",
      children: officeHours.map((item, index) => (
        <div key={index} className="pt-2">
          <h6>{item.title}</h6>
          <p>{item.text}</p>
        </div>
      )),
    },
  ];
  return (
    <>
      <ClinicNavbar />
      <div className="article">
        <Col xs={12} sm={6} md={6} className="left-home-body p-5 ">
          <ClinicSection
            title={"Kontakt"}
            desc={"Nehmen Sie jetzt Kontakt mit uns auf"}
          />
        </Col>
        <Col className="right-home-body"></Col>
      </div>
      <Container fluid>
        <Row className="p-5">
          <Col xs={12} sm={12} md={6}>
            <Row>
              {columns.map((column, index) => (
                <Col key={index}>
                  <div className="clinic-section">
                    <FooterHeader text={column.title} />
                    {column.children}
                  </div>
                </Col>
              ))}
              <Card className="location p-0 mt-1">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2450.4819444852824!2d9.361772100000001!3d52.1073593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba8efa6dbe0175%3A0xb4e66bffb6d99332!2sDr.%20med.%20Gerd%20Kuwert!5e0!3m2!1sen!2seg!4v1723981363864!5m2!1sen!2seg"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Card>
            </Row>
          </Col>

          <Col xs={12} sm={12} md={6}>
            <div className="p-0 mt-4 img-building">
              <Card>
                <Card.Img src={building} />
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default Contact;
