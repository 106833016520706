import {useState} from "react";
import {useNavigate} from "react-router-dom";

const Error401=()=>{
    const navigate=useNavigate();
    return(
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 ">
            <h3>Unauthorized to access this page.</h3>
            <h6>Please make sure you are logged in!</h6>
            <button className="btn btn-primary btn-block" onClick={() => {
                navigate('/')
            }} type="submit">Go to Home Page
            </button>
        </div>

    );
}

export default Error401;