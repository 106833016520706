import ClinicNavbar from "../ClinicNavbar";
import Footer from "../footer";
import ReferralForm from "../Forms/ReferralForm";
import { Col, Container, Row } from "react-bootstrap";
import ClinicSection from "../ClinicSection";
const Referral = () => {
  return (
    <>
      <ClinicNavbar />
      <Container fluid className="article">
        <Row>
          <Col xs={12} sm={6} md={6} className="left-home-body p-5 ">
            <ClinicSection
              title={"Überweisung"}
              desc={"Überweisungsanfrage"}
              paragraph={`Wenn Sie eine Überweisung zu einem Facharzt benötigen, können Sie hier eine Anfrage stellen. Unser Team wird Ihre Anfrage so schnell wie möglich bearbeiten und sich mit Ihnen in Verbindung setzen, um den weiteren Ablauf zu besprechen.`}
            />
          </Col>
          <Col xs={12} sm={6} md={6} className="right-home-body">
            <ReferralForm />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Referral;
