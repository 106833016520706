import { Card } from "react-bootstrap";

export const ServiceCard = ({ title, desc, price = null }) => {
  return (
    <Card className={"service-card-home m-1"}>
      <Card.Body className="p-2">
        <Card.Title className="text-success text-uppercase">{title}</Card.Title>
        <Card.Text>{desc}</Card.Text>
        <Card.Text>{price}</Card.Text>
      </Card.Body>
    </Card>
  );
};
