import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import ButtonHome from "../Buttons/ButtonHome";
import cardImage from "../../assets/about-us-home.jpg";
import  ClinicSection  from "../ClinicSection";

import { useNavigate } from "react-router-dom";

const HomeAbout = () => {
  const navigate = useNavigate();
  return (
    <Container fluid className="about-us-home py-5">
      <Row>
        <Col >
          <div className="about-us-home-pic pt-5 p-1">
            <img src={cardImage} alt="card"/>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} className="about-us-home-right">
        
          <ClinicSection title={"Team"} desc={"Lernen Sie unser Praxis-Team kennen"} paragraph={`Unser Praxisteam arbeitet eng zusammen. Wir erfüllen die
              Erwartungen unserer Patienten und die eigenen Ansprüche. Lernen
              sie uns kennen.`} />
          <ButtonHome text={"Mehr erfahren"} onClick={ ()=>{navigate("/about")}} />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeAbout;
