import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Alert, Button } from "react-bootstrap";

import React from "react";
import axios from "axios";

function ReferralForm() {
  const [validated, setValidated] = useState(false);
  const [submission, setSubmission] = useState('');
  const [clicked, setClicked] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    phoneNumber: "",
    email: "",
    drugs: "",
    speciality: "",
    reason: ""
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event) => {

    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setClicked(true);

      try {
        const response = await axios.post("/patient/get-referral", formData);
        setClicked(false);

        console.log("Form submitted successfully:", response.data);
        if (response.data.status === 'Success') {
          setSubmission('Success');
          setFormData({
            firstName: "",
            lastName: "",
            birthDate: "",
            phoneNumber: "",
            email: "",
            speciality: "",
            reason: "",
          });
          setValidated(false); // Optionally reset validation state
        } else if (response.data.status === 'Error') {
          setSubmission('Error');
        }
        setTimeout(() => {
          setSubmission('')
        }, 3000);

        // You can handle the response here, like showing a success message or redirecting
      } catch (error) {
        setClicked(false);

        console.error("There was an error submitting the form:", error);
        // Handle the error, e.g., show an error message
      }
    }
    setValidated(true);
  };


  const style = { color: "black", opacity: 0 };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="formS mt-4 mb-5"
      >
        <row className="clinic-section">
          <center>
            <h5>Überweisung</h5>
          </center>
        </row>
        <Row className="mb-3 formN">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>
              Name <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control required type="text" name={'firstName'}
              placeholder="Vorname"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label style={style}>Nachname</Form.Label>
            <Form.Control required type="text" name={'lastName'} value={formData.lastName} placeholder="Nachname" onChange={handleInputChange} />
            <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom04">
            <Form.Label>
              Geburtsdatum <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control type="date" name={'birthDate'} required={true} value={formData.birthDate} onChange={handleInputChange} />
          </Form.Group>
        </Row>
        <Row className="mb-3 formN">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>
              Telefonnummer <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control
              className="inputA"
              type="tel"
              name={'phoneNumber'}
              pattern="(\+49\s?|\b0)[1-9][0-9]{1,4}[\s/-]?[0-9]{3,9}"
              onChange={handleInputChange}
              placeholder="(+49) xxxx-xxxxxx"
              value={formData.phoneNumber}
              required
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige Telefonnummer an.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustomUsername">
            <Form.Label>E-mail</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                type="email"
                name={'email'}
                onChange={handleInputChange}
                placeholder="a@xxx.com"
                value={formData.email}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                aria-describedby="inputGroupPrepend"
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie eine gültige E-Mail-Adresse ein.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>
              Medizinisches Fachgebiet <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Select name={'speciality'} value={formData.speciality} onChange={handleInputChange} aria-label="Medizinisches Fachgebiet">
              <option value="">Bitte wählen...</option>
              <option value="Dermatologie">Dermatologie</option>
              <option value="Orthopäde">Orthopäde</option>
              <option value="Chirurgie">Chirurgie</option>
              <option value="Neurologe">Neurologe</option>
              <option value="Rheumatologe">Rheumatologe</option>
              <option value="Urologe">Urologe</option>
              <option value="Augenarzt">Augenarzt</option>
              <option value="Phlebologe">Phlebologe</option>
              <option value="HNO Arzt">HNO Arzt</option>
              <option value="Nephrologie">Nephrologie</option>
              <option value="Institusambulanz">Institusambulanz</option>
              <option value="Radiologie">Radiologie</option>
              <option value="Kardiologie">Kardiologie</option>
              <option value="Nuklearmedizin">Nuklearmedizin</option>
              <option value="Gefäßchirurgie">Gefäßchirurgie</option>
              <option value="Psychiatrie">Psychiatrie</option>
              <option value="Lungenfacharzt">Lungenfacharzt</option>
              <option value="Onkologie">Onkologie</option>
              <option value="Gynokologie">Gynokologie</option>
              <option value="Gastroenterologie">Gastroenterologie</option>
              <option value="Angiologie">Angiologie</option>
              <option value="Diabetologie">Diabetologie</option>
              <option value="Rehabilitative Medizin">Rehabilitative Medizin</option>
              <option value="Schmerzambulanz">Schmerzambulanz</option>
              <option value="Adipositaszentrum">Adipositaszentrum</option>
              <option value="Neurochirurgie">Neurochirurgie</option>
              <option value="Psychologe">Psychologe</option>
            </Form.Select>

            <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 ">
          <Form.Group as={Col} md="12" controlId="validationCustom05">
            <Form.Label>
              Grund für die ärztliche Überweisung
              <span className={"text-danger"}>*</span>
            </Form.Label>
            <textarea
              className="form-control"
              id="inputGroupPrepend"
              name="reason"
              rows={3}
              onChange={handleInputChange}
              value={formData.reason}
              placeholder="Bitte geben Sie den Grund für die ärztliche Überweisung ein."
              required
            ></textarea>
          </Form.Group>
        </Row>
        <center>
          <div className="button-home">
            <Button type={"submit"}>{clicked ? <span className="spinner-grow spinner-grow-sm" role="status"
              aria-hidden="true"></span> : 'Überweisung senden'}</Button>
          </div>
        </center>
        {submission === 'Success' && <Alert className={'backgroundColor'}>Formular erfolgreich eingereicht</Alert>}
        {submission === 'Error' && <Alert className={'bg-danger'}>Beim Einreichen des Formulars ist ein Fehler aufgetreten</Alert>}

      </Form>
    </>
  );
}
export default ReferralForm;
