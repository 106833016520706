import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import {Alert, Button} from "react-bootstrap";
import axios from "axios"; // Import Axios
import React from "react";

function PrescriptionForm() {
  const [submission,setSubmission] = useState('');
  const [clicked,setClicked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    phoneNumber: "",
    email: "",
    drugs:""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setClicked(true);


      try {
        const response = await axios.post("/patient/request-prescription/", formData);
        setClicked(false);
        console.log("Form submitted successfully:", response.data);
        // You can handle the response here, like showing a success message or redirecting
        if (response.data.status==='Success') {
          setSubmission('Success');
          setFormData({
            firstName: "",
            lastName: "",
            birthDate: "",
            phoneNumber: "",
            email: "",
            drugs: "",
          });
          setValidated(false); // Optionally reset validation state
        }else if (response.data.status==='Error') {
          setSubmission('Error');
        }
        setTimeout(() => {
          setSubmission('')
        }, 3000);
      } catch (error) {
        setClicked(false);
        console.error("There was an error submitting the form:", error);
        // Handle the error, e.g., show an error message
      }
    }
    setValidated(true);
  };

  const style = { color: "black", opacity: 0 };

  return (
      <>
        <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="formS mt-4 mb-5"
        >
          <Row className="clinic-section">
            <center>
              <h5>Rezept</h5>
            </center>
          </Row>
          <Row className="mb-3 formN">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>
                Name <span className={"text-danger"}>*</span>
              </Form.Label>
              <Form.Control
                  required
                  type="text"
                  placeholder="Vorname"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
              />
              <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom02">
              <Form.Label style={style}>Nachname</Form.Label>
              <Form.Control
                  required
                  type="text"
                  placeholder="Nachname"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
              />
              <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>
                Geburtsdatum <span className={"text-danger"}>*</span>
              </Form.Label>
              <Form.Control
                  type="date"
                  required
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleInputChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 formN">
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label>
                Telefonnummer<span className={"text-danger"}>*</span>
              </Form.Label>
              <Form.Control
                  className="inputA"
                  type="tel"
                  name="phoneNumber"
                  pattern="(\+49\s?|\b0)[1-9][0-9]{1,4}[\s/-]?[0-9]{3,9}"
                  placeholder="(+49) xxx xxx-xxxx"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie eine gültige Telefonnummer an.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
              <Form.Label>E-mail</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                    type="email"
                    placeholder="a@xxx.com"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    aria-describedby="inputGroupPrepend"
                    value={formData.email}
                    onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte geben Sie eine gültige E-Mail-Adresse ein.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom05">
              <Form.Label>
                Bestellung <span className={"text-danger"}>*</span>
              </Form.Label>
              <textarea
                  className="form-control"
                  id="inputGroupPrepend"
                  rows={3}
                  placeholder="Ihre Rezeptbestellung."
                  name="drugs"
                  value={formData.drugs}
                  onChange={handleInputChange}
                  required
              ></textarea>
            </Form.Group>
          </Row>
          <center>
            <div className="button-home">
              <Button type={"submit"}>{clicked ? <span className="spinner-grow spinner-grow-sm" role="status"
                                                       aria-hidden="true"></span>:'Rezeptbestellung'}</Button>
            </div>
          </center>
          {submission==='Success'&& <Alert className={'backgroundColor'}>Formular erfolgreich eingereicht</Alert>}
          {submission==='Error'&& <Alert className={'bg-danger'}>Beim Einreichen des Formulars ist ein Fehler aufgetreten</Alert>}

        </Form>
      </>
  );
}

export default PrescriptionForm;
