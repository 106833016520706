import React from "react";
import { Container } from "react-bootstrap";
import ClinicNavbar from "./ClinicNavbar";
import Footer from "./footer";
import ClinicSection from "./ClinicSection";

const DataProtection = () => {
  return (
    <>
      <ClinicNavbar />
      <Container className="my-5">
        <ClinicSection desc={"Datenschutz"} />

        <ClinicSection
          title="Einleitung"
          desc=""
          paragraph={`Wir freuen uns sehr über Ihr Interesse an unserer Praxis. Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsleitung der Praxis am Ring. Eine Nutzung der Internetseiten der Praxis am Ring ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere Services unserer Praxis über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.`}
        />
        <ClinicSection
          title="1. Name und Anschrift des für die Verarbeitung Verantwortlichen"
          desc=""
          paragraph={
            <div>
              <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:</p>
              <ul>
                <li>Praxis am Ring</li>
                <li>Drs. Kuwert / Ziegenhorn und Aboumaila</li>
                <li>164er Ring 9</li>
                <li>31785 Hameln</li>
                <li>Telefon: 05151 24230</li>
                <li>E-Mail: info@praxisamring-hameln.de</li>
              </ul>
            </div>
          }/>
        <ClinicSection
  title="2. Erfassung von allgemeinen Daten und Informationen"
  desc=""
  paragraph={
    <div>
      <p>Die Internetseite der Praxis am Ring erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können:</p>
      <ul>
        <li>Die verwendeten Browsertypen und Versionen,</li>
        <li>Das vom zugreifenden System verwendete Betriebssystem,</li>
        <li>Die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer),</li>
        <li>Die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden,</li>
        <li>Das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,</li>
        <li>Eine Internet-Protokoll-Adresse (IP-Adresse),</li>
        <li>Der Internet-Service-Provider des zugreifenden Systems und</li>
        <li>Sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.</li>
      </ul>
    </div>
  }
/>
        <ClinicSection
          title="3. Kontaktmöglichkeit über die Internetseite"
          desc=""
          paragraph={`Die Internetseite der Praxis am Ring enthält aufgrund von gesetzlichen Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserer Praxis sowie eine unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per E-Mail oder über ein Kontaktformular den Kontakt mit dem für die Verarbeitung Verantwortlichen aufnimmt, werden die von der betroffenen Person übermittelten personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer betroffenen Person an den für die Verarbeitung Verantwortlichen übermittelten personenbezogenen Daten werden für Zwecke der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine Weitergabe dieser personenbezogenen Daten an Dritte.`}
        />
        <ClinicSection
          title="4. SSL-Verschlüsselung"
          desc=""
          paragraph={`Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.\n
Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.`}
        />
      </Container>
      <Footer />
    </>
  );
};

export default DataProtection;
