import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Alert, Modal, Button } from "react-bootstrap";

import React from "react";
import axios from "axios";
function ClinicForm(props) {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [submission, setSubmission] = useState('');
  const [clicked, setClicked] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setClicked(true);
      const data = new FormData(form);
      const formValues = {
        patient: {
          firstName: data.get("firstName"),
          lastName: data.get("lastName"),
          birthdate: data.get("birthdate"),
          phone: data.get("phone"),
          email: data.get("email"),
          reason: data.get("reason"),
        },
        appointment: data.get("appointment"),
      };
      console.log("Form Values:", formValues);

      try {
        const response = await axios.post(
          "/patient/reserve-appointment",
          formValues
        );
        console.log("Server Response:", response);
        if (response.status === 200) {
          setSubmission('Success');
          setClicked(false);
          setValidated(false);
        }
      } catch (error) {
        setClicked(false);

        if (error.response) {
          setError(error.response.data.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    }

    setValidated(true);
  };

  const style = { color: "black", opacity: 0 };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="formS mt-4 mb-5"
      >
        <row>
          <center className="clinic-section">
            <h5>Termin Buchen</h5>
          </center>
        </row>
        <Row className="mb-3 formN">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>
              Name <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="firstName"
              placeholder="Vorname"
            />
            <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label style={style}>Nachname</Form.Label>
            <Form.Control
              required
              type="text"
              name="lastName"
              placeholder="Nachname"
            />
            <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom04">
            <Form.Label>
              Geburtsdatum <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control name="birthdate" type="date" required />
          </Form.Group>
        </Row>
        <Row className="mb-3 formN">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>
              Telefonnummer <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control
              className="inputA"
              name="phone"
              type="tel"
              pattern="[0-9]{11}"
              placeholder="(+49) xxxx-xxxxxx"
              required
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige Telefonnummer an.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustomUsername">
            <Form.Label>E-mail</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                type="email"
                name="email"
                placeholder="a@xxx.com"
                aria-describedby="inputGroupPrepend"
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie eine gültige E-Mail-Adresse ein.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
      <Form.Group as={Col} md="12" controlId="validationCustom05">
        <Form.Label>
        Termingrund<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select name={'reason'} aria-label="Grund der Ernennung">
          <option value="">Bitte wählen...</option>
          <option value="Abdomenultraschall">Abdomenultraschall</option>
          <option value="Doppler-Untersuchung">Doppler-Untersuchung</option>
          <option value="Langzeit-EKG">Langzeit-EKG</option>
          <option value="angzeit-Blutdruckmessung">Langzeit-Blutdruckmessung</option>
          <option value="Hautkrebsscreening-Test">Hautkrebsscreening-Test</option>
        </Form.Select>
      </Form.Group>
    </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom04">
            <Form.Label>
              Wählen Sie Ihren Termin<span className={"text-danger"}>*</span>
            </Form.Label>
            {/* <select required={true} name="appointment" className="form-control">
              {appointments ? (
                appointments.map((appointment, index) => (
                  <option key={index} value={appointment._id}>
                    {formatDateTime(appointment.appointment)}
                  </option>
                ))
              ) : (
                  <option>Termine werden geladen...</option>

              )}
            </select> */}
             <Form.Control
              required
              type="text"
              name="appointment"
              placeholder="Termin Datum: DD-MM-YYYY HH:MM"
            />
          </Form.Group>
          {error && <p className={"text-danger"}>{'Ungültiger Termin'}</p>}
        </Row>
        <center>
          <div className="button-home">
          <Button type={"submit"}>
            {clicked ? <span className="spinner-grow spinner-grow-sm" role="status"
                             aria-hidden="true"></span>:'Termin Buchen'}
          </Button>
          </div>
          {submission==='Success'&& <Alert className={'backgroundColor'}>Termin erfolgreich gebucht</Alert>}
        </center>
      </Form>
    </>
  );
}

export default ClinicForm;
